// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';

// Your web app's Firebase configuration

let apiKey= "AIzaSyCkHnCCbwCK1eXqMtlNQRMaDgScKXrSz9c"
let authDomain= "moznlms.firebaseapp.com"
let projectId= "moznlms"
let storageBucket= "moznlms.appspot.com"
let messagingSenderId= "461102319857"
let appId= "1:461102319857:web:6586e10f7b39aa6f428150"
let measurementId= "G-7F7HQ8455B"
// let apiKey = process.env.VUE_APP_API_KEY
// let authDomain = process.env.VUE_APP_AUTH_DOMAIN
// let projectId = process.env.VUE_APP_PROJECT_ID
// let storageBucket = process.env.VUE_APP_STORAGE_BUCKET
// let messagingSenderId = process.env.VUE_APP_MESSAGING_SENDER_ID
// let appId = process.env.VUE_APP_APP_ID
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)