import axios from "axios";
import { Keys } from '/src/config.js';
import Vue from "vue";

axios.defaults.baseURL = Keys.VUE_APP_API_URL + "/api"; // Base URL for API
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('freshToken');
axios.defaults.headers.common['connection'] = 'keep-alive';
axios.defaults.headers.common['accept-language'] = 'en-US,en;q=0.9,ar-EG;q=0.8,ar;q=0.7';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // CORS origin header

// Optional: If you need to add more CORS-related headers
axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
axios.defaults.headers.common['accept-encoding'] = 'gzip, deflate, br, zstd';
// If using withCredentials for cookies or other credentials
// axios.defaults.withCredentials = true;

